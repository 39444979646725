.doc-container {
  font-family: Arial, sans-serif;
  margin: 30px auto;
  padding: 20px;
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  color: #333;
  min-width: 80vw;
}

.doc-test-button {
  display: block;
  margin: 20px auto 0;
  padding: 10px 20px;
  background-color: #ff9800;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  text-align: center;
}

.doc-test-button:hover {
  background-color: #ff9800;
}
