.doc-preview-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.doc-preview-table th,
.doc-preview-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.doc-preview-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  white-space: nowrap; /* Prevent text wrapping */
}

.doc-preview-table td:nth-child(3), /* Unit column */
.doc-preview-table td:nth-child(4) {
  /* Qty column */
  min-width: 35px; /* Set minimum width for Unit and Qty columns */
}

.doc-preview-table-row:hover {
  background-color: #f9f9f9;
  cursor: pointer;
}

.doc-preview-table-cell {
  font-size: 0.95rem;
}
