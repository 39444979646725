/* Contact Page Container */
.contact-container {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto 20px auto;
  font-family: "Quicksand", sans-serif;
}

/* Fade-in Animation */
.contact-left,
.contact-right {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;
}

.fade-in-up {
  opacity: 1;
  transform: translateY(0);
}

/* Left Section */
.contact-left {
  flex: 1 1 60%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
}

.contact-left h1.contact-title {
  font-size: 28px;
  margin-bottom: 15px;
}

.contact-left .contact-container {
  font-size: 16px;
  margin: 0px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px 0;
}

.contact-left .contact-icon {
  font-size: 20px;
  margin: 3px -10px 0 0;
  color: #ff9800;
  width: 25px;
}

.contact-left .contact-data {
  font-size: 14px;
}

.contact-left .contact-data-link {
  font-size: 14px;
  color: black;
}

.contact-left .contact-data-link:hover {
  color: #ff9800;
}

.contact-left .social-links {
  margin: 20px 0 0 -10px;
}

.contact-left .social-icon {
  color: black;
}

.contact-photo {
  margin-top: 20px;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 8px;
  opacity: 0;
  transition: opacity 1s ease-in;
}

.contact-photo.loaded {
  opacity: 1;
}

/* Divider */
.divider {
  width: 1px;
  margin: 0 20px;
}

/* Right Section */
.contact-right {
  flex: 2 1 66%;
  max-width: 66%;
  background-color: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-right .form-header {
  font-size: 20px;
  margin: 0 0 15px 0;
}

/* Form Descriptions */
.form-description {
  margin-top: 0;
  font-size: 16px;
}

.form-description2 {
  color: rgb(59, 59, 59);
  margin-top: 0;
  font-size: 14px;
}

.form-description2 a {
  color: rgb(59, 59, 59);
  text-decoration: none;
}

.form-description2 a:hover {
  color: #ff9800;
}

/* Contact Form */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: flex-start;
}

.contact-form label {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
  gap: 5px;
  width: 100%;
}

.contact-label {
  margin-bottom: -15px;
}

.contact-form input,
.contact-form textarea,
.contact-form select {
  font-size: 14px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  margin-top: 5px;
}

/* Placeholder Styling */
.contact-form input[name="name"]::placeholder,
.contact-form input[name="email"]::placeholder {
  font-weight: normal;
}

/* Checkbox Group */
.contact-form input[type="checkbox"] {
  width: auto;
  margin-right: 10px;
}

.contact-form .checkbox-group label {
  display: flex;
  align-items: center;
}

/* Submit Button */
.contact-form button {
  background-color: black;
  color: white;
  font-size: 16px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.contact-form button:hover {
  background-color: #333;
  color: #ff9800;
}

/* Fieldset Styling */
.interests-fieldset {
  border: none;
  padding: 0px;
  margin: 0 0 10px -4px;
  border-radius: 4px;
  width: 100%;
  max-width: 301px;
}

.interests-fieldset label {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 5px 0;
}

.interests-fieldset input[type="text"] {
  margin-left: 5px;
}

.interests-fieldset-checkbox {
  padding-top: 3px;
}

.interests-fieldset-checkbox-container {
  width: 150px !important;
  padding-left: 1px;
}

.interests-fieldset-checkbox-container:hover {
  cursor: pointer;
}

.service-item {
  font-size: 14px;
  font-weight: 600;
}

/* Required Field Asterisk */
.required {
  color: #ff9800;
  font-weight: bold;
  margin-left: -3px;
}

.thank-you-message {
  max-width: 320px;
  color: #ff9800;
  font-weight: bold;
  margin-top: 40px;
}

.skill-test-container {
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: -10px;
  height: 50px;
}

.skill-test-container input {
  width: 42px;
  margin: 0 10px 0 5px;
}

.skill-test-container label {
  font-size: 16px;
  font-weight: 400;
}

.error-message {
  font-size: 14px;
  color: #ff0000;
  margin-right: auto;
}

.submit-button {
  margin-top: 20px;
}

.form-footer {
  height: 100px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .thank-you-message {
    font-size: 14px;
  }

  .contact-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .contact-left,
  .contact-right {
    width: 100%;
    flex: 1 1 100%;
  }

  .contact-right {
    box-shadow: none;
    padding: 0px;
    max-width: fit-content;
  }

  .contact-photo {
    display: none;
  }

  .contact-left .contact-data {
    font-size: 14px;
  }

  .form-description {
    max-width: 100%;
  }

  .form-description2 {
    max-width: none;
  }
}
