.doc-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.doc-modal-content {
  background-color: #ffffff;
  padding: 20px 40px 20px 20px;
  width: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.doc-modal-header {
  margin: 15px 0 30px 0;
}

.doc-modal-field {
  margin-bottom: 15px;
}

.doc-modal-label {
  display: block;
  font-size: 0.9rem;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
}

.doc-modal-input {
  width: 100%;
  margin-bottom: 0;
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.doc-modal-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.doc-modal-actions-right {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.doc-modal-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  width: 90px;
  margin-top: 20px;
}

/* Cancel button styling */
.doc-modal-cancel-button {
  background-color: #ccc;
  color: #333;
}

.doc-modal-cancel-button:hover {
  background-color: #bbb;
}

/* Save button styling */
.doc-modal-save-button {
  background-color: #4caf50;
  color: #fff;
  margin-right: -19px;
}

.doc-modal-save-button-disabled {
  background-color: #ccc;
  cursor: not-allowed;
  color: #777;
}

.doc-modal-save-button:hover:not(.doc-modal-save-button-disabled) {
  background-color: #45a049;
}

/* Delete button styling */
.doc-modal-delete-button {
  background-color: #f44336;
  color: #fff;
}

.doc-modal-delete-button:hover {
  background-color: #e53935;
}

/* Input validation error styles */
.doc-modal-label-error {
  color: #f44336;
}

.doc-modal-input-error {
  border: 1px solid #f44336;
}
