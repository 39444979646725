.doc-summary {
  margin: 40px 0;
  border-radius: 4px;
  display: flex;
  background-color: #f4f4f4;
  justify-content: center;
}

.doc-summary-table {
  border-collapse: collapse;
  margin: 10px 0;
}

.doc-summary-label {
  text-align: right;
  padding-right: 10px;
  font-size: 1rem;
  color: #333;
}

.doc-summary-currency {
  text-align: right;
  padding-right: 5px;
  font-size: 1rem;
  font-weight: bold;
}

.doc-summary-value {
  text-align: right;
  font-size: 1rem;
  font-weight: bold;
}

.doc-summary-total {
  color: #000;
}
