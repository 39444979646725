/* Footer Styling */
.footer {
    background-color: #000000;
    color: white;
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 20px;  /* Adds left padding */
    padding-right: 20px;  /* Adds right padding */
  }
  
  /* Contact Info Section (Vertically Aligned) */
  .contact-info {
    display: flex;
    flex-direction: column;  /* This makes the contact info stack vertically */
    align-items: flex-start;  /* Aligns items to the left */
    margin-right: 10px;
  }
  
  .contact-info div {
    margin-bottom: 10px; /* Adds space between each item */
  }

  .contact-data-link {
    color: white;
  }

  .contact-data-link:hover {
    color: #ff9800;
  }
  
  .social-links {
    display: flex;
    align-items: center;
  }
  
  .social-links a {
    color: white;
    margin-left: 10px;
  }
  
  .icon {
    margin-right: 5px;
    color: #ff9800;
    margin-bottom: -3px;
  }
  
  .social-icon {
    font-size: 24px;
  }

  .social-icon:hover {
    font-size: 24px;
    color: #ff9800;
  }

  .pinoy-photo {
    height: 100px;
    width: 100px;
    margin: 0 10px 0 auto;
  }
  
  @media (max-width: 1366px) {
    .footer {
      text-align: center;  /* Centers the footer content on smaller screens */
      flex-direction: row;  /* Stacks footer content vertically on smaller screens */
    }
  
    .social-links {
      display: block;
      margin-left: -10px;
      margin: 10px 0 0 -10px;
      font-size: 30px;
    }

    .pinoy-photo {
      height: 75px;
      width: 75px;
    }

  }
  