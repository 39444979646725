/* Home Container */
.home-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: "Quicksand", sans-serif;
  color: #333;
  line-height: 1.6;

  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;
}

.home-container.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.home-content {
  flex: 1 1 60%;
  min-width: 300px;
  margin-right: 20px;
}

.home-photo-container {
  flex: 1 1 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 450px;
  position: relative;
}

.home-photo {
  margin-top: 20px;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  opacity: 0;
  filter: blur(5px);
  transition: opacity 1s ease-in, filter 0.5s ease-out;
}

.home-photo.loaded {
  opacity: 1;
  filter: blur(0);
}

/* Hero Section */
.hero-section {
  text-align: left;
  margin-bottom: 40px;
}

.hero-title {
  font-size: 28px;
  color: #333;
  margin-bottom: 20px;
}

.hero-description {
  font-size: 18px;
  margin-bottom: 15px;
}

/* Services Section */
.services-section {
  margin-top: 40px;
}

.services-list {
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 15px;
}

.service-item {
  display: flex;
  align-items: center;
  font-size: 18px;
}

.icon-container {
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
}

.checkmark-icon {
  color: #ff9800;
}

.service-text {
  flex: 1;
  font-size: 20px;
}

/* Attention Section */
.attention-section {
  margin: 0;
  background-color: black;
  color: white;
  text-align: center;
  padding: 40px 40px;
  border-radius: 0;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.attention-text-container {
  max-width: 800px;
}

.attention-text,
.attention-caption,
.attention-cta-button {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.attention-text {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 10px;
}

.attention-caption {
  font-weight: regular;
  font-size: 18px;
  margin-bottom: 20px;
}

.attention-cta-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #ff9800;
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease, opacity 1s ease, transform 1s ease;
  max-width: 150px;
}

.attention-cta-button:hover {
  background-color: #333;
}

/* Consultation Section */
.consultation-section {
  margin: 20px 0 10px 0;
  background-color: #333;
  border-radius: 8px;
  color: white;
  padding: 20px 40px 40px 40px;
  flex: 1 1 60%;
}

.consultation-text-container {
  font-size: 18px;
}

.consultation-text {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.consultation-caption {
  font-weight: regular;
  margin-bottom: 20px;
}

.consultation-cta-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #ff9800;
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  max-width: 150px;
  margin-top: 20px;
  border: 1px solid #ff9800;
}

.consultation-cta-button:hover {
  background-color: #333;
}

/* Mobile Photo and Consultation Section */
.home-photo-container-mobile {
  display: none;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;
}

.home-photo-container-mobile.fade-in {
  opacity: 1;
  transform: translateY(0);
}

.home-photo-mobile {
  border-radius: 0;
  width: 100%;
  height: 300px;
  object-fit: cover;
  margin-bottom: -10px;
  filter: brightness(50%);
}

.home-photo-mobile-cta {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline-block;
  padding: 15px 30px;
  border: 1px solid white;
  background-color: rgba(0, 0, 0, 0);
  color: white;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  text-align: center;
  transition: background-color 0.3s ease;
  white-space: nowrap;
}

.home-photo-mobile-cta:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.consultation-section-mobile {
  display: none;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease, transform 1s ease;
}

.consultation-section-mobile.fade-in {
  opacity: 1;
  transform: translateY(0);
}

@media (max-width: 768px) {
  .attention-text {
    font-size: 20px;
  }

  .attention-caption {
    font-size: 16px;
  }

  .home-photo-container {
    display: none;
  }

  .consultation-section {
    display: none;
  }

  .home-photo-container-mobile {
    display: block;
    margin-top: 10px;
    position: relative;
  }

  .consultation-section-mobile {
    display: block;
    background-color: #333;
    color: white;
    padding: 20px 20px 40px 20px;
  }
}
