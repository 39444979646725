html {
  height: 100%;
  margin: 0;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  padding: 20px;
}

footer {
  margin-top: auto;
}

/* Global Styles */
body {
  font-family: 'Quicksand', Arial, sans-serif;
  font-size: 16px;
  margin: 0;
  padding: 0;
  line-height: 1.6;
  background-color: white;
  color: black;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Quicksand', Arial, sans-serif;
  font-weight: 700;
}

a {
  font-family: inherit;
  text-decoration: none;
}

button {
  font-family: inherit;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  body {
    font-size: 14px; /* Reduce font size for tablets */
  }
}

@media (max-width: 480px) {
  body {
    font-size: 12px; /* Reduce font size for phones */
  }
}
