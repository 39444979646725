/* General Styles */
.header {
    background-color: white;
    color: black;
    position: sticky;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 20px;
  }

  .header-container .logo {
    margin-left: -3px;
  }
  
  .logo img {
    height: 80px; /* Adjust size of the logo */
    object-fit: contain;
  }
  
  .logo a {
    display: flex;
    align-items: center;
  }
  
  /* Desktop Navigation */
  .desktop-nav {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  
  .desktop-nav a {
    color: black;
    text-decoration: none;
  }
  
  .desktop-nav a:hover {
    color: #ff9800;
  }
  
  /* Active Link */
  .desktop-nav a.active {
    color: #ff9800; /* Highlight color for the active link */
    border-bottom: 2px solid #ff9800; /* Optional: underline the active link */
  }
  
  /* CTA Button Styling */
  .nav-cta-button {
    background-color: black;
    color: white !important; /* Force text to be white */
    padding: 10px 15px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 14px;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
    text-align: center;
    display: flex;
    text-wrap: nowrap;
  }
  
  .nav-cta-button:hover {
    background-color: #333;
    color: #ff9800; /* Hover effect: change text color to orange */
  }
  
  /* CTA Button Styling for Drawer */
  .mobile-nav .drawer-cta {
    font-size: 12px; /* Smaller font size for drawer */
    text-align: center; /* Center text inside the button */
    margin-top: 20px; /* Add spacing above the button */
    padding: 8px 10px; /* Adjust padding for smaller button size */
    background-color: black;
    color: white !important;
    text-decoration: none;
    border-radius: 5px;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .drawer-cta:hover {
    background-color: #333;
    color: #ff9800;
  }

  .mobile-drawer .drawer-logo {
    height: 100px;
    width: 100px;
    margin-top: auto;
    margin-bottom: 50px;
    cursor: pointer;
  }
  
  /* Hamburger Menu */
  .hamburger {
    background: none;
    border: none;
    display: none; /* Hidden on desktop */
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    z-index: 1001; /* Higher than the drawer to remain visible */
    position: relative; /* Ensure proper stacking */
  }
  
  .hamburger .line {
    width: 25px;
    height: 3px;
    background-color: black;
    border-radius: 2px;
  }
  
  .hamburger:focus {
    outline: none;
  }
  
  /* Mobile Drawer */
  .mobile-drawer {
    position: fixed;
    top: 0;
    right: -100%; /* Hidden by default */
    width: 45%;
    height: 100%;
    background-color: white;
    transition: right 0.3s ease;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 999; /* Above the overlay */
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  
  .mobile-drawer.open {
    right: 0; /* Show drawer when open */
  }
  
  .mobile-nav {
    display: flex;
    flex-direction: column;
    justify-items: start;
    gap: 20px;
    align-items: center;
    flex: 1;
    padding-top: 20px;
  }
  
  .mobile-nav a {
    color: black;
    text-decoration: none;
    font-size: 18px;
  }
  
  .mobile-nav a.active {
    color: #ff9800; /* Highlight color for active mobile link */
  }
  
  .mobile-nav a:hover {
    color: #ff9800;
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .desktop-nav {
      display: none; /* Hide desktop nav on mobile */
    }
  
    .hamburger {
      display: flex; /* Show hamburger menu on mobile */
    }
  
    .logo img {
      height: 40px; /* Smaller logo size on mobile */
    }
  }

  /* Overlay */
  .drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay */
    z-index: 998; /* Below the drawer, above the main content */
  }
  
  /* Mobile Drawer */
  .mobile-drawer {
    position: fixed;
    top: 0;
    right: -100%; /* Hidden by default */
    width: 45%;
    height: 100%;
    background-color: white;
    transition: right 0.3s ease;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    z-index: 999; /* Above the overlay */
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
  
  .mobile-drawer.open {
    right: 0; /* Show drawer when open */
  }
  