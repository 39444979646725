.doc-add-button-container {
  border: none;
  font-size: 1.5rem;
  text-align: center;
  line-height: 50px;
}

.doc-add-button:hover {
  cursor: pointer;
}
