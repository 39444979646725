.projects {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: "Quicksand", sans-serif;
}

h1.projects-title {
  font-size: 28px;
  margin-bottom: 20px;
  opacity: 0; /* Initially invisible */
  transform: translateY(20px); /* Start slightly lower */
  transition: opacity 1s ease, transform 1s ease; /* Smooth fade-in and upward movement */
}

.projects-title.fade-in-up {
  opacity: 1; /* Fully visible */
  transform: translateY(0); /* Move to original position */
}

/* Thumbnail Layout */
.thumbnails {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;
}

.thumbnail {
  width: 150px;
  height: 150px;
  object-fit: cover;
  cursor: pointer;
  border: 3px solid transparent;
  border-radius: 5px;
  opacity: 0; /* Initially invisible */
  transform: translateY(20px); /* Start slightly lower */
  transition: opacity 1s ease, transform 1s ease, border-color 0.3s ease;
}

.thumbnail.fade-in-up {
  opacity: 1; /* Fully visible */
  transform: translateY(0); /* Move to original position */
}

.thumbnail.active {
  border-color: #007bff; /* Highlight active thumbnail */
}

/* Modal Styling */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal Content (Image) */
.modal-content {
  position: relative;
  text-align: center;
  max-width: 80%;
  max-height: 80%;
}

.modal-content img {
  width: auto;
  height: auto;
  max-width: 70vw;
  max-height: 70vh;
  border-radius: 8px;
}

/* Modal Navigation Arrows */
.nav-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  color: white;
  font-size: 30px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  padding-bottom: 8px;
  background: none;
}

.nav-arrow-left {
  left: 20px;
}

.nav-arrow-right {
  right: 20px;
}

.nav-arrow:hover {
  color: #ff9800;
  background: none;
}

/* Close Button Styling */
.close-button {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 24px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  padding-bottom: 3px;
}

.close-button:hover {
  color: #ff9800;
  background: none;
}

.modal:focus {
  outline: none;
}

/* Mobile-Specific Adjustments */
@media (max-width: 768px) {
  .nav-arrow {
    font-size: 24px;
    width: 40px;
    height: 40px;
    padding-bottom: 8px;
  }

  .nav-arrow-left {
    left: 10px;
  }

  .nav-arrow-right {
    right: 10px;
  }

  .close-button {
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    padding-bottom: 3px;
  }

  .thumbnail {
    width: 26vw;
    height: 26vw;
  }
}

/* Optional Hover Effect for Thumbnails */
.thumbnail:hover {
  border-color: #ff9800;
  opacity: 1;
}
