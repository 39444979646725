.doc-form-fields {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

.doc-form-row {
  display: flex;
  gap: 10px;
}

.doc-form-row:first-child {
  flex-direction: column; /* Client field takes its own row */
}

.doc-form-row > * {
  flex: 1;
}

.doc-form-field {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.doc-form-field-label {
  font-size: 0.9rem;
  margin-bottom: 5px;
  font-weight: bold;
}

.doc-form-field-input,
.doc-form-field-select {
  padding: 8px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
}
